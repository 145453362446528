
import {Vue, Component, Prop, Inject} from 'vue-property-decorator';
import {globals} from "../../env";
import {AuthProvider} from "../model/model";
import {LanguageConfig} from "../../game/tarock/language/languages";
import GoogleButton from "./buttons/GoogleButton.vue";
import FacebookButton from "./buttons/FacebookButton.vue";
import MicrosoftButton from "./buttons/MicrosoftButton.vue";

@Component({
  components: {MicrosoftButton, FacebookButton, GoogleButton}
})
export default class ExternalLogin extends Vue {
  @Inject() readonly lc!: LanguageConfig;

  private googleLoginUrl: string | null = null;
  private facebookLoginUrl: string | null = null;
  private microsoftLoginUrl: string | null = null;

  async created() {
    const response = await fetch(globals.SERVER_URL + 'auth/auth-providers', {
      credentials: 'include'
    });

    if (response.ok) {
      const authProviders = await response.json() as AuthProvider[];
      authProviders.forEach(provider => {
        switch (provider.$type) {
          case 'Google':
            this.googleLoginUrl = provider.authUri;
            break;
          case 'Facebook':
            this.facebookLoginUrl = provider.authUri;
            break;
          case 'Microsoft':
            this.microsoftLoginUrl = provider.authUri;
            break;
        }
      })
    }
  }

  private removeErrorCode(stringUrl: string) {
    const chunks = stringUrl.split('?')

    if (chunks.length < 1) {
      return stringUrl;
    }

    const params = new URLSearchParams(chunks[1]);
    params.delete('error');

    let ret = chunks[0];
    let search = params.toString();
    if (search) {
      return ret + '?' + search;
    } else {
      return ret;
    }
  }

  private rewriteUrlState(stringUrl: string) {
    const url = new URL(stringUrl);

    const state = url.searchParams.get('state');

    if (!state) {
      return stringUrl;
    }

    const base = JSON.parse(state);

    const thisUrl = window.location.pathname + this.removeErrorCode(window.location.search) + this.removeErrorCode(window.location.hash);
    const newState = {...base, redirect: thisUrl};
    url.searchParams.set('state', JSON.stringify(newState));
    return url.toString();
  }

  private googleLogin() {
    if (!this.googleLoginUrl) {
      console.error("Google login URL is missing");
      return;
    }

    window.location.href = this.rewriteUrlState(this.googleLoginUrl);
  }

  private facebookLogin() {
    if (!this.facebookLoginUrl) {
      console.error("Facebook login URL is missing");
      return;
    }

    window.location.href = this.rewriteUrlState(this.facebookLoginUrl);
  }

  private microsoftLogin() {
    if (!this.microsoftLoginUrl) {
      console.error("Google login URL is missing");
      return;
    }

    window.location.href = this.rewriteUrlState(this.microsoftLoginUrl);
  }
}
