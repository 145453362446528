export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

export function getOrThrow<T>(t: T | undefined, name: string): T {
  if (t === undefined) {
    throw new Error(`${name} was undefined`);
  }
  return t;
}

export function nullArray(length: number): null[] {
  const arr: null[] = [];
  for (let i = 0; i < length; i++) {
    arr.push(null);
  }
  return arr;
}

export function b64DecodeUnicode(str: string): string {
  return decodeURIComponent(Array.prototype.map.call(window.atob(str), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}
