import {Figure} from '../data/figure';

export namespace hu {
  const figureText: Record<Figure['kind'], string> = {
    volat: 'Volát',
    contra: 'Kontra',
    party: 'Parti',
    trull: 'Tulétroá',
    ulti: 'Ulti',
    'double-play': 'Duplajáték',
    'eight-trumps': '8 Tarokk',
    'four-kings': '4 Király',
    'nine-trumps': '9 Tarokk',
    'xxi-catch': '21 Fogás',
  };

  export const text = {
    ui: {
      enterNick: 'Felhasználónév',
      join: 'Játék indítása',
      joinPrivate: 'Csatlakozás az asztalhoz',
      createPrivate: 'Privát asztal',
      greeting: 'Üdv',
      auth: {
        loginWithGoogle: 'Bejelentkezés Google-fiókkal',
        loginWithFacebook: 'Bejelentkezés Facebook-fiókkal',
        loginWithMicrosoft: 'Bejelentkezés Microsoft-fiókkal',
        register: 'Regisztrácó'
      },
      'action-box': {
        exchanged: (cards: number) => `${cards} lapot fektetett`,
        bid: (bid: number | null, hold: boolean) => {
          const bidText = hu.text.phase.bidding.bid(bid);
          return `licit: ${hold ? 'Tartom (' + bidText + ')': bidText }`
        },
        called: (trump: number) => `Meghívta: ${trump}-s tarokk `,
        disconnected: 'Kilépett',
        connected: 'Belépett',
        reconnected: 'Újracsatlakozott',
        'tarots-exchanged': (numb: number) => `${numb} tarokk fekszik`,
        'trying-for-honor': 'honőr nélkül',
        table: 'Asztal',
        gameOver: 'Parti vége',
      },
      'score-board': {
        scoreBoard: 'Pontok',
        player: 'Játékos',
        partner: 'Partner',
        opponent: 'Ellenfél'
      },
      reportBug: {
        name: 'Hibajelentés',
        description: 'Hibajelenség',
      },
      send: 'Küldés',
      close: 'Bezárás',
      loadingAssets: 'Tartalom betöltése...',
      loadingGame: 'Várakozás játékosokra...',
      dealer: 'Osztó',
      player: 'Játékos',
      contact: 'Kapcsolat',
      logout: 'Kijelentkezés',
      userNameTooLong: (min: number, max: number) => `Túl hosszú név, ${min} és ${max} karakter között kell lennie`,
      userNameTooShort: (min: number, max: number) => `Túl rövid név, ${min} és ${max} karakter között kell lennie`,
      userAlreadyExists: (user: string) => `${user} név már foglalt`,
      genericError: 'Váratlan hiba történt',
      version: 'Verzió',
      rotateMessage: 'Növeld meg az ablakot, vagy forgasd el a kijelződet!',
      invite: {
        text: 'Játékosok meghívása',
        copy: 'Másolás',
        share: 'Megosztás',
        description: 'Oszd meg az alábbi linket a meghívni kívánt játékosokkal:'
      },
      reconnectModal: {
        title: 'Újracsatlakozás',
        exit: 'Kilépés',
        message: 'A kapcsolat megszakadt. Szeretnél megpróbáni újracsatlakozni?',
        ok: 'Újracsatlakozás'
      },
      errorCode: {
        access_denied: 'Hozzáférés megtagadva',
        unexpected_state: 'Váratlan állapot',
        unknown_provider: 'Ismeretlen szolgáltató',
        missing_state: 'Hiányzó adat'
      }
    },
    phase: {
      bidding: {
        bid: (bid: number | null) => {
          const bidTexts = ['Szóló', 'Egy', 'Kettő', 'Három'];
          return bid === null ? 'Passz' : bidTexts[bid];
        },
        hold: (numBid: number) => {
          const bidText = hu.text.phase.bidding.bid(numBid);
          return `Tartom (${bidText})`
        }
      },
      exchanging: {
        endExchange: 'Fektetés',
        throw: 'Bedobás'
      },
      partnerCalling: {
        callPartner: 'Tarokk hívása',
      },
      figures: {
        figs: figureText,
        mandatory: 'Kötelező',
        contra: (depth: number) =>
          ['Kontra', 'Rekontra', 'Szubkontra', 'Hirschkontra'][depth - 1],
        pass: 'Passz',
        silent: 'Csendes',
      },
    },
  };
}
