
import {Vue, Component, Inject, Prop} from 'vue-property-decorator';
import {LanguageConfig} from "../../../game/tarock/language/languages";

@Component({})
export default class FacebookButton extends Vue {
  @Inject() readonly lc!: LanguageConfig;

  @Prop({required: false}) disabled!: boolean;

}
