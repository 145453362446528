
import {Component, Inject, Prop, Vue, Watch} from "vue-property-decorator";
  import {LanguageConfig, LC} from '../../game/tarock/language/languages';
  import {globals} from '../../env';
  import {SharedStore} from '../../game/tarock/control/sharedStore';
  import {CONST} from '../../game/tarock/const/const';
import {Dictionary, RawLocation, Route} from 'vue-router/types/router';
  import ExternalLogin from "./ExternalLogin.vue";
  import {b64DecodeUnicode} from "../../game/tarock/utils/utils";

  type UserError =
    | { $type: 'NameTooLong', min: number, max: number }
    | { $type: 'NameTooShort', min: number, max: number }
    | { $type: 'UserAlreadyExists', name: string }

  function isUserError(value: any): value is UserError {
    return value && ['NameTooLong', 'NameTooShort', 'UserAlreadyExists'].includes(value.$type);
  }

  @Component({
    components: {ExternalLogin}
  })
  export default class LandingPage extends Vue {
    @Inject() readonly lc!: LanguageConfig;
    @Inject() readonly sharedStore!: SharedStore;

    @Prop({required: false}) id!: string;
    @Prop({required: false}) loginState!: string;
    @Prop({required: false}) redirect!: string;
    @Prop({required: false}) error!: string;

    playerName: string = '';
    placeholderShowing = true;
    loading = true;
    errorText: ((lc: LC) => string) | null = null;
    version: string = '';
    isLoggedIn: boolean = false;

    private contactMail = CONST.CONTACT_MAIL;

    async created() {
      await this.fetchLoggedInState();
      this.fetchVersion();
      this.sharedStore.isPrivateGameCreator = false;
      this.updateErrorMessage(undefined, this.error);
      this.routeChanged(this.$route);
    }

    private setLoggedInState(userName: string) {
      this.isLoggedIn = true;
      this.loading = false;
      this.playerName = userName;
      this.placeholderShowing = false;
    }

    private clearLoggedInState() {
      this.isLoggedIn = false;
      this.loading = false;
      this.playerName = '';
      this.placeholderShowing = true;
    }

    private async fetchLoggedInState() {
      try {
        const response = await fetch(globals.SERVER_URL + 'auth/check-login-state', {
          credentials: 'include',
          method: 'GET'
        });
        if (response.ok) {
          const userName = await response.json();
          this.setLoggedInState(userName);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }

    private async fetchVersion() {
      try {
        const response = await fetch(globals.SERVER_URL + 'version', {credentials: 'include'});
        if (response.ok) {
          this.version = await response.text();
        }
      } catch (e) {
        console.error(e);
      }
    }

    private async register() {
      this.loading = true;

      try {
        const state = decodeURIComponent(this.loginState);
        const response = await fetch(globals.SERVER_URL + 'auth/register', {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({username: this.playerName, state})
        });

        if (response.ok) {
          this.errorText = null;
          const userName = await response.json();
          this.setLoggedInState(userName);

          let redir = this.redirect;
          if (redir.startsWith('/#')) {
            redir = redir.substring(2);
          }

          if (redir !== this.$route.fullPath) {
            await this.$router.push(redir);
          }
        } else {
          await this.handleError(response);
        }
      } finally {
        this.loading = false;
      }
    }

    private async handleError(response: Response) {
      if (!response.ok) {
        try {
          const err = await response.json();
          console.warn(err);
          this.showUserError(err);
        } catch (err) {
          console.error(err);
          this.showUserError(null);
        }
        return false;
      }
      return true;
    }

    async join() {
      this.sharedStore.userInteracted = true;

      // TODO: do this but only on mobile
      // const el = document.querySelector('#tarokk-app');
      // if (el && !document.fullscreenElement) {
      //   el.requestFullscreen().then(
      //       () => console.log('SUCCESS'),
      //       reason => console.warn('NOT SUCCESS', reason)
      //   );
      // }

      this.errorText = null;
      if (this.isPrivateGame) {
        await this.$router.push({ path: `/game/${this.id}`});
      } else {
        await this.$router.push({ path: '/game' });
      }
    }

    async createPrivate() {
      this.sharedStore.userInteracted = true;
      const idResponse = await fetch(globals.SERVER_URL + 'game/create-table', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const json = await idResponse.json();
      const id = json.id;
      if (!json.id || typeof json.id !== 'string') {
        this.errorText = lc => lc.ui.genericError;
        return;
      }

      this.errorText = null;
      this.error = '';
      this.sharedStore.isPrivateGameCreator = true;
      await this.$router.push({ path: `/game/${id}`});
    }

    private showUserError(err: any) {
      this.playerName = '';
      const input = this.$refs['player-name'] as HTMLElement;
      if (input) {
        input.focus();
      }

      if (isUserError(err)) {
        switch (err.$type) {
          case 'NameTooLong':
            this.errorText = lc => lc.ui.userNameTooLong(err.min, err.max);
            break;
          case 'NameTooShort':
            this.errorText = lc => lc.ui.userNameTooShort(err.min, err.max);
            break;
          case 'UserAlreadyExists':
            this.errorText = lc => lc.ui.userAlreadyExists(err.name);
            break;
        }
        return;
      }
      this.errorText = lc => lc.ui.genericError;
    }

    submitOnEnter(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.join();
      }
    }

    get isPrivateGame(): boolean {
      return !!this.id;
    }

    private async logout() {
      const response = await fetch(globals.SERVER_URL + 'auth/log-out', {
        credentials: 'include',
        method: 'POST'
      });
      if (response.ok) {
        this.clearLoggedInState();
        this.errorText = null;
        if ('/' !== this.$route.fullPath) {
          await this.$router.push('/');
        }
      } else {
        this.errorText = (lc: LC) => lc.ui.genericError;
      }
    }

    @Watch('error')
    private updateErrorMessage(oldVal?: string, newVal?: string) {
      if (oldVal == newVal) {
        return;
      }

      if (!newVal) {
        this.errorText = null;
        return;
      }

      this.errorText = (lc: LC) => {
        const maybeText = lc.ui.errorCode[newVal];
        return maybeText || lc.ui.genericError;
      }
    }

    @Watch('$route')
    private routeChanged(newVal: Route) {
      function errorToString(error: string | (string | null)[]): string {
        if (typeof error === 'string') {
          return error;
        }
        return error.join(', ');
      }

      // The backend passes the error message as a query param during a redirect,
      // which is ugly so we are removing the query param and turning it into
      // a param. This way, the UI won't keep showing the error after e.g. page refresh.
      if (newVal.query && newVal.query['error']) {
        const query = { ...newVal.query };
        const params = { ...newVal.params };

        params['error'] = errorToString(query['error']);
        delete query['error'];
        this.$router.replace({ path: newVal.path, query, params });
      }
    }
  }
