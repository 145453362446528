
    import {Component, Vue} from "vue-property-decorator";
    import UiButton from "../../game/tarock/ui/Button.vue";
    import Bidding from "../../game/tarock/ui/Bidding.vue";
    import FigureButtons from "../../game/tarock/ui/FigureButtons.vue";
    import {FigureStateHandler} from '../../game/tarock/control/figureStateHandler';
    import Hand from '../../game/tarock/ui/game/Hand.vue';
    import {Deck} from '../../game/tarock/data/deck';
    import EnemyHand from '../../game/tarock/ui/game/EnemyHand.vue';
    import PlayerBox from '../../game/tarock/ui/game/PlayerBox.vue';
    import GameTable from '../../game/tarock/ui/game/GameTable.vue';
    import CallPartner from '../../game/tarock/ui/CallPartner.vue';
    import {Card, Suite} from '../../game/tarock/data/card';
    import ScoreBoard from "../../game/tarock/ui/ScoreBoard.vue";
    import {last} from "rxjs/operators";
    import BugReporter from "./BugReporter.vue";
    import LoadingScreen from '../../game/tarock/ui/LoadingScreen.vue';
    import PrivateGameInviter from '../../game/tarock/ui/PrivateGameInviter.vue';
    import ExternalLogin from "./ExternalLogin.vue";

    @Component({
      components: {
        UiButton,
        Bidding,
        FigureButtons,
        Hand,
        EnemyHand,
        PlayerBox,
        GameTable,
        CallPartner,
        ScoreBoard,
        BugReporter,
        LoadingScreen,
        PrivateGameInviter,
        ExternalLogin
      }
    })
    export default class Playground extends Vue {
      trumps = [
        new Card(Suite.Tarock, 2),
        new Card(Suite.Tarock, 18),
        new Card(Suite.Tarock, 7),
        new Card(Suite.Tarock, 3),
        new Card(Suite.Tarock, 9),
        new Card(Suite.Tarock, 20),
        new Card(Suite.Tarock, 17),
        new Card(Suite.Tarock, 6),
      ];

      cards = Deck.generateDeck().slice(0, 9);
      addCard() {
        const lastRank = this.cards.length > 0 ? this.cards[this.cards.length - 1].rank : 0;
        this.cards.push(new Card(Suite.Tarock, lastRank + 1));
      }

      scores = {
        player: 'Jozsi',
        partner: 'Bela',
        opponents: ['Pista', 'Margit'],
        partyValue: 1,
        scoreTable: [
          {
            figure: {kind: "xxi-catch"},
            score: 21,
            silent: true,
            wonBy: 'player'
          },
          {
            figure: {kind: "ulti"},
            score: 5,
            silent: true,
            wonBy: 'enemy'
          },
          {
            figure: {kind: 'contra', contraCount: 1, baseFigure: {kind: "xxi-catch"}},
            score: 12,
            silent: false,
            wonBy: 'player'
          },
          {
            figure: {kind: "volat"},
            score: 6,
            silent: false,
            wonBy: 'enemy'
          },
        ]
      };

      lmao = [
        {text: 'Pass', active: true, selected: false},
        {text: '4 kings', active: false, selected: false}
      ];

      stateHandler: FigureStateHandler = new FigureStateHandler();

      created() {
        this.stateHandler.figures = {
          kind: 'figures',
          hastoContra: true,
          mandatory: [{kind: 'double-play'}, {kind: 'four-kings'}],
          possible: [
            [{kind: 'eight-trumps'}],
            [{kind: 'eight-trumps'}, {kind: 'ulti'}],
            [{kind: 'contra', baseFigure: {kind: 'trull'}, contraCount: 5}]
          ],
          figures: [],
        };
      }

      availableBids: Array<number | null> = [];

      mounted() {
        window['bids'] = this.availableBids;
      }

      foo() {
        this.lmao.forEach(x => x.active = true);
        this.lmao.forEach(x => x.selected = true);
      }
    }
