import Vue from 'vue';
import GameComponent from './frontend/components/Game.vue'
import LandingPageComponent from './frontend/components/LandingPage.vue';
import UiButtonComponent from './game/tarock/ui/Button.vue';
import PlaygroundComponent from './frontend/components/Playground.vue';
import {LanguageConfig} from './game/tarock/language/languages';
import VueRx from 'vue-rx';
import VueRouter from 'vue-router';
import {RealController} from './game/tarock/control/realController';
import './style.scss';
import {UserRepo} from './game/tarock/control/userRepo';
import {UiController} from './game/tarock/control/uiController';
import {BootstrapVue} from 'bootstrap-vue'
import {SharedStore} from './game/tarock/control/sharedStore';
import PrivacyPolicy from "./static/PrivacyPolicy.vue";
import TermsOfService from "./static/TermsOfService.vue";

Vue.use(VueRx);
Vue.use(VueRouter);
Vue.use(BootstrapVue);

const routes = [
  {path: '/', component: LandingPageComponent},
  {
    path: '/join-game/:id',
    component: LandingPageComponent,
    props: route => ({ ...route.query, ...route.params })
  },
  {
    path: '/game/:id?',
    component: GameComponent,
    props: route => ({ ...route.query, ...route.params })
  },
  {path: '/playground', component: PlaygroundComponent},
  {
    path: '/external-login',
    component: LandingPageComponent,
    props: route => ({ ...route.query, ...route.params })
  },
  {path: '/privacy-policy', component: PrivacyPolicy},
  {path: '/tos', component: TermsOfService}

];

const router = new VueRouter({
  routes,
});

function createLc(): LanguageConfig {
  const lc = new LanguageConfig();
  lc.language = 'hu';
  const rLc = new Vue({data: {lc}});

  return rLc.lc;
}

const userRepo = new UserRepo();
const gameController = new RealController(userRepo);

let v = new Vue({
  el: '#app',
  template: `
    <div id="tarokk-app">
      <router-view></router-view>
    </div>
  `,
  components: {
    GameComponent,
    LandingPageComponent,
    UiButtonComponent,
    PlaygroundComponent
  },
  provide: {
    lc: createLc(),
    gameController: gameController,
    userRepo: userRepo,
    uiController: new UiController(),
    sharedStore: new SharedStore(),
  },
  router
});
